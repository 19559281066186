import { filter, propOr } from 'ramda';
import { IFlags } from 'flagsmith/types';
import {
  CONSULTANT_GROUPS_MANAGEMENT,
  CUSTOMER_REGISTRATION,
  SEARCH,
  CLIENT_PROFILE,
  TASK_PLATFORM,
  LOAN_RESTRUCTURING_PANEL,
  LOAN_RESTRUCTURING,
  CUSTOMER_SERVICE,
  NEGOTIATION,
  FUNDING_ASSIGNMENTS,
  FUNDING_REGISTRATION,
  FUNDING_BLOCK_LIST,
  FUNDING_RESTRUCTURING,
  FUNDING_ACCOUNTABILITY,
} from '../featureToggleConfig';
import { PageStructure } from './interfaces/PageStructure';

const config: PageStructure[] = [
  {
    template: 'two-columns',
    route: '/minhas-tarefas/negociacao',
    featureName: NEGOTIATION,
    default: true,
    microfrontends: [
      {
        name: 'negotiationTaskList',
        elementId: 'negotiation-task-list',
        bundle: '/task-list/index.singlespa.js',
        container: 'right',
      },
    ],
  },
  {
    template: 'two-columns',
    route: '/minhas-tarefas/atendimento',
    featureName: CUSTOMER_SERVICE,
    default: true,
    microfrontends: [
      {
        name: 'customerServiceTaskList',
        elementId: 'customer-service-task-list',
        bundle: '/customer-service-task-list/index.singlespa.js',
        container: 'right',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/gestao-de-grupos',
    featureName: CONSULTANT_GROUPS_MANAGEMENT,
    default: false,
    microfrontends: [
      {
        name: 'consultantGroupsManagement',
        elementId: 'consultant-groups-management',
        bundle: '/consultant-groups-management/index.singlespa.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/alteracao-de-cadastro',
    featureName: CUSTOMER_REGISTRATION,
    default: false,
    microfrontends: [
      {
        name: 'customerRegistration',
        elementId: 'customer-registration',
        bundle: '/customer-registration/customer-registration.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    featureName: SEARCH,
    route: '/oraculo',
    default: false,
    microfrontends: [
      {
        name: 'greencareOraculo',
        elementId: 'greencare-oraculo',
        bundle: '/greencare-oraculo/greencare-oraculo.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/nova-ficha-do-cliente',
    featureName: CLIENT_PROFILE,
    default: false,
    microfrontends: [
      {
        name: 'clientProfile',
        elementId: 'client-profile',
        bundle: '/client-profile/index.singlespa.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/tarefas',
    featureName: TASK_PLATFORM,
    default: false,
    microfrontends: [
      {
        name: 'taskPlatform',
        elementId: 'task-platform',
        bundle: '/task-platform/index.singlespa.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/reestruturacoes',
    featureName: LOAN_RESTRUCTURING_PANEL,
    default: false,
    microfrontends: [
      {
        name: 'loanRestructuringPanel',
        elementId: 'loan-restructuring-frontend',
        bundle: '/loan-restructuring/loan-restructuring-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/reestruturacao',
    featureName: LOAN_RESTRUCTURING,
    default: false,
    microfrontends: [
      {
        name: 'loanRestructuring',
        elementId: 'loan-restructuring-frontend',
        bundle: '/loan-restructuring/loan-restructuring-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/cnab',
    featureName: FUNDING_ASSIGNMENTS,
    default: true,
    microfrontends: [
      {
        name: 'fundingCnab',
        elementId: 'funding-frontend',
        bundle: '/funding/cnab.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/cessao',
    featureName: FUNDING_ASSIGNMENTS,
    default: true,
    microfrontends: [
      {
        name: 'fundingAssignment',
        elementId: 'funding-assignment',
        bundle: '/funding-assignment/funding-assignment.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/selecao-de-lotes',
    featureName: FUNDING_ASSIGNMENTS,
    default: true,
    microfrontends: [
      {
        name: 'fundingSelectionBatchPanel',
        elementId: 'funding-eligibility-selection-frontend',
        bundle: '/funding-eligibility-selection/funding-eligibility-selection-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/prestacao-de-contas',
    featureName: FUNDING_ACCOUNTABILITY,
    default: true,
    microfrontends: [
      {
        name: 'fundingAccountabilityCollectionPanel',
        elementId: 'funding-eligibility-selection-frontend',
        bundle: '/funding-eligibility-selection/funding-eligibility-selection-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/carteira/elegibilidade',
    featureName: FUNDING_ASSIGNMENTS,
    default: true,
    microfrontends: [
      {
        name: 'fundingWalletEligibilityPanel',
        elementId: 'funding-eligibility-selection-frontend',
        bundle: '/funding-eligibility-selection/funding-eligibility-selection-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/estoques',
    featureName: FUNDING_ASSIGNMENTS,
    default: true,
    microfrontends: [
      {
        name: 'fundingSelectionStock',
        elementId: 'funding-eligibility-selection-frontend',
        bundle: '/funding-eligibility-selection/funding-eligibility-selection-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/comunicacoes-de-reestruturacoes',
    featureName: FUNDING_RESTRUCTURING,
    default: true,
    microfrontends: [
      {
        name: 'loanRestructuringReportPanel',
        elementId: 'funding-restructuring-frontend',
        bundle: '/funding-restructuring/funding-restructuring-frontend.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/elegibilidade/bloqueios-de-contrato',
    featureName: FUNDING_BLOCK_LIST,
    default: true,
    microfrontends: [
      {
        name: 'fundingEligibility',
        elementId: 'funding-eligibility',
        bundle: '/funding-eligibility/funding-eligibility.js',
        container: 'single',
      },
    ],
  },
  {
    template: 'single-page',
    route: '/funding-platform/credores',
    featureName: FUNDING_REGISTRATION,
    default: true,
    microfrontends: [
      {
        name: 'credRegistration',
        elementId: 'funding-frontend',
        bundle: '/funding/cnab.js',
        container: 'single',
      },
    ],
  },
];

export const getConfig = (permissions?: IFlags<string>): PageStructure[] => {
  if (!permissions) {
    return config;
  }

  return filter(
    (item: PageStructure) => propOr(false, 'enabled', permissions[item.featureName]) === true,
    config,
  );
};
